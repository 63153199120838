@use "../abstracts/variables";
@use "../abstracts/mixins";

.input-container {
    position: relative;

    &:not(:last-child) {
        margin-bottom: variables.$rem-size-3;
    }

    label {
        display: block;
        font-weight: 600;
        padding-bottom: variables.$rem-size-2;
        font-size: variables.$rem-size-4;
        text-align: left;
    }

    input:not([type="checkbox"]):not([type="radio"]):not([type="range"]),
    textarea {
        padding: 15px;
        position: relative;
        z-index: 1;
        background-color: #fff;
        width: 100%;
        border-width: 2px;
        @include mixins.transition;
        @include mixins.border-all;

        &:hover,
        &:focus {
            border-color: variables.$blue;
            box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.25);
        }
    }

    input[type="range"] {
        appearance: none;
        width: 100%;
        height: 20px;
        border-radius: 50px;
        background-color: variables.$grey-300;
        border: 2px solid variables.$grey-500;
        outline: none;
        opacity: 0.75;
        @include mixins.transition;
        @include mixins.pseudo;

        &:focus {
            &::-webkit-slider-thumb {
                outline: auto;
            }
        }

        &:hover,
        &:focus {
            opacity: 1;
        }

        &::-webkit-slider-thumb {
            appearance: none;
            width: 35px;
            height: 35px;
            border-radius: 100%;
            background-color: var(--brand-primary-500);
            border: 3px solid var(--brand-primary-700);
            cursor: pointer;
            box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.7);
        }

        &::-webkit-slider-runnable-track {
            border-radius: 50px;
        }

        & + .markers {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;

            li {
                margin: 0 12px;
            }
        }
    }

    select {
        width: 100%;
        box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.3);
        padding: variables.$rem-size-2;
        font-size: variables.$rem-size-3;
        background-color: #ffffff;
        @include mixins.border-all;
        @include mixins.transition;

        &:hover,
        &:focus {
            box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.25);
            border-color: variables.$blue;
        }

        &:disabled,
        &[readonly] {
            background-color: #ebebe4;
            border-color: variables.$grey-600;
            cursor: not-allowed;

            &:hover,
            &:focus {
                border-color: variables.$grey-600;
                box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.3);
            }
        }
    }

    &.has-checkbox {
        position: relative;

        label {
            background-color: #ffffff;
            display: flex;
            align-items: center;
            cursor: pointer;
            @include mixins.pseudo;
            @include mixins.transition;

            span {
                &:not(.text) {
                    border-radius: 3px;
                    background-color: #ffffff;
                    padding: calc(#{variables.$rem-size-1} / 2);
                    margin-right: variables.$rem-size-2;
                    transform: scale(1);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.3);
                    @include mixins.eq-size(variables.$rem-size-4);
                    @include mixins.border-all(2px, solid, var(--brand-primary-500));
                    @include mixins.transition(all 0.2s ease);
                    @include mixins.pseudo;

                    svg {
                        fill: none;
                        stroke: #ffffff;
                        stroke-width: 2;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        stroke-dasharray: 16px;
                        stroke-dashoffset: 16px;
                        transform: translate3d(0, 0, 0);
                        @include mixins.transition(all 0.3s 0.1s ease);
                    }

                    &:after {
                        left: 0;
                        top: 0;
                        border-radius: 100%;
                        background-color: var(--brand-primary-500);
                        transform: scale(0);
                        @include mixins.eq-size(100%);
                    }
                }

                &.text {
                    word-break: break-word;
                    flex: 1;
                    user-select: none;
                }

                &:only-child {
                    margin-right: 0;
                }
            }

            &:hover {
                text-decoration: underline;

                span {
                    &:not(.text) {
                        border-color: var(--brand-primary-700);
                    }
                }
            }
        }

        input[type="checkbox"] {
            position: absolute;
            top: 0;
            left: 0;
            @include mixins.eq-size(0px);

            & + label:before {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            &:checked + label {
                span:not(.text) {
                    background-color: var(--brand-primary-500);
                    border-color: var(--brand-primary-700);
                    @include mixins.animation("check-box-feedback 0.2s ease");

                    svg {
                        stroke-dashoffset: 0;
                    }

                    &:after {
                        transform: scale(3);
                        opacity: 0;
                        @include mixins.transition(all 0.5s ease);
                    }
                }
            }
        }
    }

    &.has-radio {
        position: relative;

        label {
            background-color: #ffffff;
            display: flex;
            align-items: center;
            cursor: pointer;
            @include mixins.pseudo;
            @include mixins.transition;

            span {
                &:not(.text) {
                    border-radius: 100%;
                    background-color: #ffffff;
                    padding: calc(#{variables.$rem-size-1} / 2);
                    margin-right: variables.$rem-size-2;
                    transform: scale(1);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.3);
                    @include mixins.eq-size(variables.$rem-size-4);
                    @include mixins.border-all(2px, solid, var(--brand-primary-500));
                    @include mixins.transition(all 0.2s ease);
                    @include mixins.pseudo;

                    &:after {
                        left: 0;
                        top: 0;
                        border-radius: 100%;
                        background-color: var(--brand-primary-500);
                        transform: scale(0);
                        @include mixins.eq-size(100%);
                    }
                }

                &.text {
                    word-break: break-word;
                    flex: 1;
                    user-select: none;
                }

                &:only-child {
                    margin-right: 0;
                }
            }

            &:hover {
                text-decoration: underline;

                span {
                    &:not(.text) {
                        border-color: var(--brand-primary-700);
                    }
                }
            }
        }

        input[type="checkbox"] {
            position: absolute;
            top: 0;
            left: 0;
            @include mixins.eq-size(0px);

            & + label:before {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            &:checked + label {
                span:not(.text) {
                    background-color: var(--brand-primary-500);
                    border-color: var(--brand-primary-700);
                    @include mixins.animation("check-box-feedback 0.2s ease");

                    svg {
                        stroke-dashoffset: 0;
                    }

                    &:after {
                        transform: scale(3);
                        opacity: 0;
                        @include mixins.transition(all 0.5s ease);
                    }
                }
            }
        }
    }

    &.has-toggle {
        label {
            span:not(.text) {
                width: 75px;
                height: 40px;
                background-color: #fff;
                border-radius: 50px;
                display: block;
                box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.3);
                display: flex;
                align-items: center;
                cursor: pointer;
                overflow: hidden;
                @include mixins.pseudo;
                @include mixins.border-all;
                border-width: 2px;

                &:after {
                    background-color: #ddd;
                    border-radius: 100%;
                    left: 5px;
                    @include mixins.transition(all 0.5s ease);
                    @include mixins.eq-size(30px);
                }

                &:before {
                    @include mixins.eq-size(100%);
                    top: 0;
                    left: 0;
                }
            }
        }

        input[type="checkbox"] {
            position: absolute;
            top: 0;
            left: 0;

            &:checked + label {
                span:not(.text) {
                    border-color: var(--brand-primary-500);

                    &:after {
                        background-color: var(--brand-primary-500);
                        left: calc(100% - 35px);
                    }

                    &:before{
                        background-color: var(--brand-primary-500);
                        opacity: .15;
                    }
                }
            }
        }
    }
}

.floating-placeholder {
    background-color: variables.$grey-100;
    display: flex;
    flex: 1;
    position: relative;

    input {
        border: none;
        background-color: transparent;
        padding: 30px 20px 15px 50px;
        position: relative;
        z-index: 1;
        box-shadow: none;
        user-select: none;
        @include mixins.eq-size(100%);
        @include mixins.border-all;
        border-color: variables.$grey-300;

        &:focus {
            outline: none;
        }

        &::-webkit-input-placeholder,
        &::placeholder {
            color: transparent;
        }

        &:-ms-input-placeholder {
            color: transparent;
        }

        &:focus + label:after {
            transform: scale3d(1, 1, 1);
            opacity: 1;
        }

        &:placeholder-shown + label:before {
            transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
        }

        &:focus + label:before {
            transform: translate3d(0, -15px, 0) scale3d(0.75, 0.75, 1);
            color: var(--brand-primary-500);
        }

        &:focus + label + svg {
            fill: var(--brand-primary-500);
        }
    }

    & > svg {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        @include mixins.eq-size(25px);
    }

    label {
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        pointer-events: none;
        font-size: variables.$rem-size-3;
        padding-bottom: 0;
        overflow: hidden;
        padding-right: 20px;
        @include mixins.eq-size(100%);
        @include mixins.pseudo(absolute);

        &:before {
            content: attr(aria-label);
            transform-origin: left top;
            left: 50px;
            @include mixins.transition;
            @include mixins.truncate(calc(100% - 30px));
        }

        &:before {
            transform: translate3d(0, -15px, 0) scale3d(0.75, 0.75, 1);
        }

        &:after {
            height: 2px;
            position: absolute;
            opacity: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: var(--brand-primary-500);
            transform: scale3d(0, 1, 1);
            @include mixins.transition;
        }
    }
}

.radial-percentage {
    display: block;
    margin: 10px auto;
    max-height: 200px;

    .fill {
        fill: none;
        stroke-width: 2;
        stroke-linecap: round;
        stroke: var(--brand-primary-500);
        animation: progress 1s ease-out forwards;
        @include mixins.transition;
    }

    .background {
        fill: none;
        stroke: var(--brand-primary-500);
        stroke-width: 2.8;
        opacity: 0.1;
    }

    text {
        fill: #666;
        text-anchor: middle;
        font-family: sans-serif;

        &.percentage {
            font-size: 0.5rem;
        }

        &:not(.percentage) {
            font-size: 0.175rem;
        }
    }
}
