@use "../abstracts/variables";
@use "../abstracts/mixins";

.contact {
    .inline-img {
        float: left;
        width: 100%;
        max-width: 300px;
        margin-right: 20px;
        margin-bottom: 20px;

        @include mixins.media-query(custom, max-width, 700px) {
            float: none;
        }
    }

    .has-map {
        background-color: #fff;
    }

    iframe {
        min-height: 400px;
    }
}
