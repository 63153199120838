@use "../abstracts/variables";
@use "../abstracts/mixins";

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.logo {
    font-family: 'Dancing Script', cursive;
    font-size: 44px;

    @include mixins.media-query(laptop) {
        font-size: 36px;
    }

    @include mixins.media-query(tablet) {
        flex: 1;
        font-size: 28px;
    }
}

.mobile-menu-toggle {
    color: #fff;
    @include mixins.eq-size(30px);

    svg {
        fill: currentColor;
    }
}

ul.is-mobile {
    position: fixed;
    top: 0;
    right: -90%;
    width: 90%;
    height: 100%;
    background-color: #fff;
    flex-direction: column;
    z-index: 9;
    padding: 20px;
    transition: 0.3s;

    &.mobile-navigation-active {
        right: 0;
    }

    .mobile-menu-close {
        display: flex;
        justify-content: flex-end;

        button {
            color: variables.$black;
            @include mixins.eq-size(30px);
        }

        svg {
            fill: currentColor;
        }
    }

    li{
        margin: initial!important;
    }

    a {
        color: variables.$black;
        font-size: 18px;
        padding: 10px;
        display: block;
    }
}

.mobile-overlay {
    background-color: rgba(variables.$black, 75%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    @include mixins.eq-size(100%);
}
