@use "./variables";

@mixin darkMode() {
    @media (prefers-color-scheme: dark) {
        body.dark-mode & {
            @content;
        }
    }

    body.dark-mode & {
        @content;
    }
}

@mixin rtl() {
    html[dir="rtl"] & {
        @content;
    }
}

@mixin transition($what: all 0.3s) {
    transition: $what;
}

@mixin truncate($truncate-width) {
    max-width: $truncate-width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin eq-size($size) {
    width: $size;
    height: $size;
}

@mixin border-all($width: 1px, $style: solid, $color: variables.$grey-300) {
    border: $width $style $color;
}

@mixin border-top($width: 1px, $style: solid, $color: variables.$grey-300) {
    border-top: $width $style $color;
}

@mixin border-right($width: 1px, $style: solid, $color: variables.$grey-300) {
    border-right: $width $style $color;
}

@mixin border-bottom($width: 1px, $style: solid, $color: variables.$grey-300) {
    border-bottom: $width $style $color;
}

@mixin border-left($width: 1px, $style: solid, $color: variables.$grey-300) {
    border-left: $width $style $color;
}

@mixin pseudo($position: relative, $display: block) {
    @if $position=="relative" {
        position: relative;

        &:before,
        &:after {
            position: absolute;
        }
    }

    @else if $position=="absolute" {
        position: absolute;

        &:before,
        &:after {
            position: relative;
        }
    }

    @else if $position=="fixed" {
        position: fixed;

        &:before,
        &:after {
            position: absolute;
        }
    }

    @else {
        @error "Property #{$position} must be relative, absolute or fixed.";
    }

    &:before,
    &:after {
        content: '';
        display: $display;
    }
}

@mixin keyframes($animation-name) {
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($value) {
    animation: #{$value};
}

@mixin media-query($resolution, $minMax: max-width, $pixels: variables.$desktopBreakpoint) {
    @if $resolution==desktop {
        @media only screen and ($minMax: variables.$desktopBreakpoint) {
            @content;
        }
    }

    @else if $resolution==laptop {
        @media only screen and ($minMax: variables.$laptopBreakpoint) {
            @content;
        }
    }

    @else if $resolution==tablet {
        @media only screen and ($minMax: variables.$tabletBreakpoint) {
            @content;
        }
    }

    @else if $resolution==mobile {
        @media only screen and ($minMax: variables.$mobileBreakpoint) {
            @content;
        }
    }

    @else if $resolution==custom {
        @media only screen and ($minMax: $pixels) {
            @content;
        }
    }
}

@mixin browserHack($browser) {
    @if $browser==edge {
        @supports (-ms-ime-align:auto) {
            @content;
        }
    }

    @else if $browser==ie {

        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            @content;
        }
    }

    @else if $browser==ie9 {
        @media all and (min-width:0\0) and (min-resolution:.001dpcm) {
            @content;
        }
    }

    @else if $browser==firefox {
        @-moz-document url-prefix() {
            @content;
        }
    }
}