@use "../abstracts/variables";
@use "../abstracts/mixins";

header {
    color: #fff;

    .cta {
        padding: 15px 30px;
        background-color: #fff;
        color: var(--brand-primary-500);
        display: inline-flex;
        font-size: 18px;
        font-weight: 600;
        margin-top: 50px;

        @include mixins.media-query(tablet) {
            margin-top: 25px;
        }
    }
}

header,
nav {
    background-color: var(--brand-primary-500);
}

nav,
header .heading-details,
section.has-clients,
main,
footer {
    padding: 50px 75px;
    position: relative;

    @include mixins.media-query(laptop) {
        padding: 30px 55px;
    }

    @include mixins.media-query(laptop) {
        padding: 20px 30px;
    }

    @include mixins.media-query(tablet) {
        padding: 10px 20px;
    }
}

section.has-clients {
    padding-top: 0;
}

nav,
footer {
    display: flex;

    & > a {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: variables.$rem-size-4;

        @include mixins.media-query(tablet) {
            margin-bottom: 15px;
        }

        svg {
            width: 50px;
            height: 50px;
            display: inline-block;
            margin-right: 10px;
        }
    }

    ul,
    .details {
        flex: 1;

        @include mixins.media-query(tablet) {
            text-align: left;
        }
    }
}

nav {
    display: flex;
    color: #fff;
    align-items: center;

    & > a {
        color: #fff;
        svg {
            fill: #fff;
        }
    }

    ul {
        justify-content: flex-end;
        display: flex;

        @include mixins.media-query(tablet) {
            justify-content: flex-start;
            white-space: nowrap;
            overflow: auto;
        }

        li {
            &:not(:last-child) {
                margin-right: 30px;
            }

            a {
                color: #fff;

                &.active {
                    text-decoration: underline;
                }
            }
        }
    }
}

footer {
    text-align: right;
    padding-top: 50px;
    border-top: 2px solid var(--brand-primary-500);
    font-weight: 700;
    background-color: #f8f8f8;

    & > a {
        color: var(--brand-primary-500);

        svg {
            fill: var(--brand-primary-500);
        }
    }
}

main:not(.home) {
    padding-top: 0;
    @include mixins.pseudo;

    @include mixins.media-query(laptop) {
        padding: 0 30px 55px 30px;
    }

    @include mixins.media-query(tablet) {
        padding: 0 15px 30px 20px;
    }

    &:before {
        width: 100%;
        height: 200px;
        top: 0;
        left: 0;
        background-color: var(--brand-primary-500);
        z-index: -1;
    }

    &.contact {
        .wrapper {
            background-color: #fff;
            box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.15);

            .content {
                box-shadow: none;

                .users {
                    display: flex;
                    gap: 20px;

                    .user:not(:first-child) {
                        border-left: 3px dashed variables.$grey-200;
                        padding-left: 30px;

                        @include mixins.media-query(tablet) {
                            padding-left: initial;
                            border-left: initial;
                            border-top: 3px dashed variables.$grey-200;
                            padding-top: 30px;
                        }
                    }

                    .user:not(:last-child) {
                        padding-right: 30px;
                    }

                    & > * {
                        flex: 1;
                    }

                    @include mixins.media-query(tablet) {
                        flex-direction: column;
                    }
                }
            }
        }
    }

    .content {
        padding: 50px;
        background-color: #fff;
        box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.15);
        position: relative;
        z-index: 1;

        @include mixins.media-query(laptop) {
            padding: 25px;
        }

        h1 {
            font-weight: 400;
        }
    }
}
