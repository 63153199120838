@use "../abstracts/variables";
@use "../abstracts/mixins";

.image-gallery {
    .title {
        font-weight: 500;
        font-size: variables.$rem-size-4;
        margin-bottom: variables.$rem-size-4;
        @include mixins.border-bottom;
    }
    
    .gallery {

        ul {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
            grid-gap: 25px;

            @include mixins.media-query(tablet) {
                grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
            }

            li {
                background-color: rgb(255, 255, 255);
                box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.15);
                @include mixins.border-all;
                @include mixins.transition(all 0.3s);

                &:hover {
                    transform: translate3d(0, -2px, 0);
                    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.12),
                        0 6px 18px rgba(0, 0, 0, 0.12);
                }

                &:focus-within {
                    transform: translate3d(0, -2px, 0);
                    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.12),
                        0 6px 18px rgba(0, 0, 0, 0.12);
                }

                button {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    cursor: pointer;
                    width: 100%;
                    position: relative;

                    .full-screen {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        border-radius: 100%;
                        background-color: #fff;
                        padding: 7.5px;
                        opacity: 0;
                        @include mixins.transition;
                        @include mixins.border-all;
                        @include mixins.eq-size(35px);

                        svg {
                            @include mixins.eq-size(100%);
                        }
                    }

                    img {
                        object-fit: cover;
                        object-position: 50%;
                        width: 100%;
                        flex: 1;
                        max-height: 300px;
                    }

                    .content {
                        padding: 20px;
                        @include mixins.border-top;
                        flex-shrink: 0;
                        width: 100%;

                        p {
                            font-weight: 600;
                        }
                    }

                    &:hover,
                    &:focus {
                        .full-screen {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &:not(:last-child) {
        margin-bottom: 20px;
    }
}
