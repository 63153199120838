@import url("https://use.typekit.net/qtp1qcs.css");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
*,
*:before,
*:after,
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  -webkit-font-smoothing: antialiased;
}

*:before,
*:after {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-decoration: inherit;
}

input::placeholder,
input::-webkit-input-placeholder,
input::-moz-placeholder {
  opacity: 0.85;
}

@media (prefers-color-scheme: dark) {
  body.dark-mode ::placeholder,
body.dark-mode ::-webkit-input-placeholder,
body.dark-mode ::-moz-placeholder {
    color: #f8f8f8;
  }
}
body.dark-mode ::placeholder,
body.dark-mode ::-webkit-input-placeholder,
body.dark-mode ::-moz-placeholder {
  color: #f8f8f8;
}

a {
  color: #5224f3;
  font-weight: 600;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

address {
  font-style: normal;
}
address:not(:last-child) {
  padding-bottom: 0.9375rem;
}
address li:not(:last-child) {
  padding-bottom: 5px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
picture {
  display: block;
}

figcaption {
  display: none;
}

body,
button,
input,
select,
textarea {
  font-family: "proxima-nova", sans-serif;
}

button,
input,
textarea {
  appearance: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

blockquote {
  position: relative;
  padding: 20px;
  padding-left: 40px;
  background-color: #f8f8f8;
  position: relative;
}
blockquote:before, blockquote:after {
  position: absolute;
}
blockquote:before, blockquote:after {
  content: "";
  display: block;
}
blockquote:before {
  content: '"';
  top: 10px;
  left: 10px;
  font-size: 50px;
  font-family: sans-serif;
  opacity: 0.5;
}
blockquote:not(:last-child) {
  margin-bottom: 0.9375rem;
}

body {
  background-color: #f8f9fa;
}
body.no-scroll {
  overflow: hidden;
}

button {
  background-color: transparent;
}

.clear {
  clear: both;
}

*:disabled {
  cursor: not-allowed;
}

.d-none,
.inline-svg {
  display: none !important;
}

.d-block {
  display: block;
}

svg {
  display: block;
  width: 100%;
  height: 100%;
  transition: all 0.3s;
}

.v-hidden {
  position: absolute;
  left: -99999px;
}

fieldset {
  min-width: initial;
  display: block;
}

form:not(.width-auto) {
  width: 100%;
}
form fieldset {
  position: relative;
}

p:empty {
  display: none;
}

html,
body {
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
  color: rgb(40, 40, 40);
}

iframe {
  width: 100%;
  height: 100%;
  display: block;
}

img {
  display: block;
  height: auto;
  max-width: 100%;
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: #5224f3;
  font-weight: 700;
}
.link:hover {
  text-decoration: underline;
}
.link:disabled {
  cursor: not-allowed;
}

ol:not(.has-bullet-points),
ul:not(.has-bullet-points) {
  list-style: none;
}
ol.has-bullet-points,
ul.has-bullet-points {
  padding-left: 20px;
}
ol.has-bullet-points li:not(:last-of-type),
ul.has-bullet-points li:not(:last-of-type) {
  padding-bottom: 0.9375rem;
}
ol.has-bullet-points li ul,
ul.has-bullet-points li ul {
  padding-top: 0.9375rem;
}
ol:not(:last-child),
ul:not(:last-child) {
  padding-bottom: 0.9375rem;
}

p {
  word-break: break-word;
}
p:empty {
  padding-bottom: 0;
}

section {
  width: 100%;
  position: relative;
}

select::-ms-expand {
  display: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
table caption {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.text-off-screen {
  text-indent: -99999px;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.word-break-initial,
.word-break-initial * {
  word-break: initial !important;
}

.center {
  margin: 0 auto;
  display: block;
}

.skip-to-content {
  position: fixed;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  overflow: hidden;
  z-index: 999;
  padding: 1.25rem;
  background-color: rgb(40, 40, 40);
  color: #ffffff;
  font-size: 1.25rem;
}

.user-tabbing .skip-to-content:active, .user-tabbing .skip-to-content:focus, .user-tabbing .skip-to-content:hover {
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  overflow: visible;
  outline-color: yellow;
}

@media (prefers-reduced-motion) {
  *,
*:before,
*:after {
    transition: none !important;
  }
}
.mb-1 {
  margin-bottom: 0.3125rem;
}

.mb-2 {
  margin-bottom: 0.625rem;
}

.mb-3 {
  margin-bottom: 0.9375rem;
}

.mb-4 {
  margin-bottom: 1.25rem;
}

.mt-1 {
  margin-top: 0.3125rem;
}

.mt-2 {
  margin-top: 0.625rem;
}

.mt-3 {
  margin-top: 0.9375rem;
}

.mt-4 {
  margin-top: 1.25rem;
}

.error {
  color: #E22C2C;
}

html {
  font-size: 1em;
}

button,
input,
p,
select,
textarea {
  font-size: 1rem;
}

h1:not(:last-child),
h2:not(:last-child),
h3:not(:last-child),
h4:not(:last-child),
h5:not(:last-child),
h6:not(:last-child),
p:not(:last-child) {
  padding-bottom: 0.9375rem;
}

h1 {
  font-weight: 700;
  font-size: 2.125rem;
}
@media only screen and (max-width: 1000px) {
  h1 {
    font-size: 1.75rem;
  }
}

h2 {
  font-weight: 600;
  font-size: 1.75rem;
}
@media only screen and (max-width: 1000px) {
  h2 {
    font-size: 1.5rem;
  }
}

h3 {
  font-weight: 500;
  font-size: 1.5rem;
}
@media only screen and (max-width: 1000px) {
  h3 {
    font-size: 1.25rem;
  }
}

h4 {
  font-weight: 400;
  font-size: 1.25rem;
}

h5 {
  font-weight: 300;
  font-size: 1.125rem;
}

h6 {
  font-weight: 300;
  font-size: 1rem;
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes check-box-feedback {
  50% {
    transform: scale(0.8);
  }
}
@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}
.button {
  cursor: pointer;
  padding: 10px 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: rgb(40, 40, 40);
  background-color: #fff;
  border-radius: 5px;
  font-weight: 600;
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.15);
  text-align: center;
  font-size: 18px;
  border: 2px solid #dee2e6;
  position: relative;
  transition: all 0.3s;
}
.button:hover, .button:focus {
  transform: translate3d(0, -2px, 0);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.12), 0 6px 18px rgba(0, 0, 0, 0.12);
  text-decoration: underline;
}
.button.button-primary {
  background-color: #1a73e8;
  color: #fff;
  border-color: #0e458e;
}

.empty-state {
  border-radius: 100%;
  background-color: #f8f9fa;
  padding: 0.9375rem;
  margin: 0 auto;
  margin-bottom: 20px;
  width: 80px;
  height: 80px;
  border: 1px solid #dee2e6;
  border-width: 2px;
}
.empty-state .icon {
  width: 100%;
  height: 100%;
}
.empty-state .icon.success {
  fill: #388505;
}

.empty-state-illustration .highlight {
  fill: var(--brand-primary-500);
}
.empty-state-illustration .white {
  fill: #fff;
}
.empty-state-illustration .off-white {
  fill: #f2f2f2;
}
.empty-state-illustration .light-skin {
  fill: #ffb8b8;
}
.empty-state-illustration .off-black {
  fill: #3f3d56;
}
.empty-state-illustration .grey {
  fill: #cccccc;
}

.input-container {
  position: relative;
}
.input-container:not(:last-child) {
  margin-bottom: 0.9375rem;
}
.input-container label {
  display: block;
  font-weight: 600;
  padding-bottom: 0.625rem;
  font-size: 1.25rem;
  text-align: left;
}
.input-container input:not([type=checkbox]):not([type=radio]):not([type=range]),
.input-container textarea {
  padding: 15px;
  position: relative;
  z-index: 1;
  background-color: #fff;
  width: 100%;
  border-width: 2px;
  transition: all 0.3s;
  border: 1px solid #dee2e6;
}
.input-container input:not([type=checkbox]):not([type=radio]):not([type=range]):hover, .input-container input:not([type=checkbox]):not([type=radio]):not([type=range]):focus,
.input-container textarea:hover,
.input-container textarea:focus {
  border-color: #7854F6;
  box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.25);
}
.input-container input[type=range] {
  appearance: none;
  width: 100%;
  height: 20px;
  border-radius: 50px;
  background-color: #dee2e6;
  border: 2px solid #dddddd;
  outline: none;
  opacity: 0.75;
  transition: all 0.3s;
  position: relative;
}
.input-container input[type=range]:before, .input-container input[type=range]:after {
  position: absolute;
}
.input-container input[type=range]:before, .input-container input[type=range]:after {
  content: "";
  display: block;
}
.input-container input[type=range]:focus::-webkit-slider-thumb {
  outline: auto;
}
.input-container input[type=range]:hover, .input-container input[type=range]:focus {
  opacity: 1;
}
.input-container input[type=range]::-webkit-slider-thumb {
  appearance: none;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: var(--brand-primary-500);
  border: 3px solid var(--brand-primary-700);
  cursor: pointer;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.7);
}
.input-container input[type=range]::-webkit-slider-runnable-track {
  border-radius: 50px;
}
.input-container input[type=range] + .markers {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.input-container input[type=range] + .markers li {
  margin: 0 12px;
}
.input-container select {
  width: 100%;
  box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.3);
  padding: 0.625rem;
  font-size: 0.9375rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  transition: all 0.3s;
}
.input-container select:hover, .input-container select:focus {
  box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.25);
  border-color: #7854F6;
}
.input-container select:disabled, .input-container select[readonly] {
  background-color: #ebebe4;
  border-color: #6c757d;
  cursor: not-allowed;
}
.input-container select:disabled:hover, .input-container select:disabled:focus, .input-container select[readonly]:hover, .input-container select[readonly]:focus {
  border-color: #6c757d;
  box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.3);
}
.input-container.has-checkbox {
  position: relative;
}
.input-container.has-checkbox label {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
}
.input-container.has-checkbox label:before, .input-container.has-checkbox label:after {
  position: absolute;
}
.input-container.has-checkbox label:before, .input-container.has-checkbox label:after {
  content: "";
  display: block;
}
.input-container.has-checkbox label span:not(.text) {
  border-radius: 3px;
  background-color: #ffffff;
  padding: calc(0.3125rem / 2);
  margin-right: 0.625rem;
  transform: scale(1);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.3);
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--brand-primary-500);
  transition: all 0.2s ease;
  position: relative;
}
.input-container.has-checkbox label span:not(.text):before, .input-container.has-checkbox label span:not(.text):after {
  position: absolute;
}
.input-container.has-checkbox label span:not(.text):before, .input-container.has-checkbox label span:not(.text):after {
  content: "";
  display: block;
}
.input-container.has-checkbox label span:not(.text) svg {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s 0.1s ease;
}
.input-container.has-checkbox label span:not(.text):after {
  left: 0;
  top: 0;
  border-radius: 100%;
  background-color: var(--brand-primary-500);
  transform: scale(0);
  width: 100%;
  height: 100%;
}
.input-container.has-checkbox label span.text {
  word-break: break-word;
  flex: 1;
  user-select: none;
}
.input-container.has-checkbox label span:only-child {
  margin-right: 0;
}
.input-container.has-checkbox label:hover {
  text-decoration: underline;
}
.input-container.has-checkbox label:hover span:not(.text) {
  border-color: var(--brand-primary-700);
}
.input-container.has-checkbox input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 0px;
}
.input-container.has-checkbox input[type=checkbox] + label:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.input-container.has-checkbox input[type=checkbox]:checked + label span:not(.text) {
  background-color: var(--brand-primary-500);
  border-color: var(--brand-primary-700);
  animation: check-box-feedback 0.2s ease;
}
.input-container.has-checkbox input[type=checkbox]:checked + label span:not(.text) svg {
  stroke-dashoffset: 0;
}
.input-container.has-checkbox input[type=checkbox]:checked + label span:not(.text):after {
  transform: scale(3);
  opacity: 0;
  transition: all 0.5s ease;
}
.input-container.has-radio {
  position: relative;
}
.input-container.has-radio label {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
}
.input-container.has-radio label:before, .input-container.has-radio label:after {
  position: absolute;
}
.input-container.has-radio label:before, .input-container.has-radio label:after {
  content: "";
  display: block;
}
.input-container.has-radio label span:not(.text) {
  border-radius: 100%;
  background-color: #ffffff;
  padding: calc(0.3125rem / 2);
  margin-right: 0.625rem;
  transform: scale(1);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.3);
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid var(--brand-primary-500);
  transition: all 0.2s ease;
  position: relative;
}
.input-container.has-radio label span:not(.text):before, .input-container.has-radio label span:not(.text):after {
  position: absolute;
}
.input-container.has-radio label span:not(.text):before, .input-container.has-radio label span:not(.text):after {
  content: "";
  display: block;
}
.input-container.has-radio label span:not(.text):after {
  left: 0;
  top: 0;
  border-radius: 100%;
  background-color: var(--brand-primary-500);
  transform: scale(0);
  width: 100%;
  height: 100%;
}
.input-container.has-radio label span.text {
  word-break: break-word;
  flex: 1;
  user-select: none;
}
.input-container.has-radio label span:only-child {
  margin-right: 0;
}
.input-container.has-radio label:hover {
  text-decoration: underline;
}
.input-container.has-radio label:hover span:not(.text) {
  border-color: var(--brand-primary-700);
}
.input-container.has-radio input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 0px;
}
.input-container.has-radio input[type=checkbox] + label:before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.input-container.has-radio input[type=checkbox]:checked + label span:not(.text) {
  background-color: var(--brand-primary-500);
  border-color: var(--brand-primary-700);
  animation: check-box-feedback 0.2s ease;
}
.input-container.has-radio input[type=checkbox]:checked + label span:not(.text) svg {
  stroke-dashoffset: 0;
}
.input-container.has-radio input[type=checkbox]:checked + label span:not(.text):after {
  transform: scale(3);
  opacity: 0;
  transition: all 0.5s ease;
}
.input-container.has-toggle label span:not(.text) {
  width: 75px;
  height: 40px;
  background-color: #fff;
  border-radius: 50px;
  display: block;
  box-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border: 1px solid #dee2e6;
  border-width: 2px;
}
.input-container.has-toggle label span:not(.text):before, .input-container.has-toggle label span:not(.text):after {
  position: absolute;
}
.input-container.has-toggle label span:not(.text):before, .input-container.has-toggle label span:not(.text):after {
  content: "";
  display: block;
}
.input-container.has-toggle label span:not(.text):after {
  background-color: #ddd;
  border-radius: 100%;
  left: 5px;
  transition: all 0.5s ease;
  width: 30px;
  height: 30px;
}
.input-container.has-toggle label span:not(.text):before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.input-container.has-toggle input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
}
.input-container.has-toggle input[type=checkbox]:checked + label span:not(.text) {
  border-color: var(--brand-primary-500);
}
.input-container.has-toggle input[type=checkbox]:checked + label span:not(.text):after {
  background-color: var(--brand-primary-500);
  left: calc(100% - 35px);
}
.input-container.has-toggle input[type=checkbox]:checked + label span:not(.text):before {
  background-color: var(--brand-primary-500);
  opacity: 0.15;
}

.floating-placeholder {
  background-color: #f8f9fa;
  display: flex;
  flex: 1;
  position: relative;
}
.floating-placeholder input {
  border: none;
  background-color: transparent;
  padding: 30px 20px 15px 50px;
  position: relative;
  z-index: 1;
  box-shadow: none;
  user-select: none;
  width: 100%;
  height: 100%;
  border: 1px solid #dee2e6;
  border-color: #dee2e6;
}
.floating-placeholder input:focus {
  outline: none;
}
.floating-placeholder input::-webkit-input-placeholder, .floating-placeholder input::placeholder {
  color: transparent;
}
.floating-placeholder input:-ms-input-placeholder {
  color: transparent;
}
.floating-placeholder input:focus + label:after {
  transform: scale3d(1, 1, 1);
  opacity: 1;
}
.floating-placeholder input:placeholder-shown + label:before {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
}
.floating-placeholder input:focus + label:before {
  transform: translate3d(0, -15px, 0) scale3d(0.75, 0.75, 1);
  color: var(--brand-primary-500);
}
.floating-placeholder input:focus + label + svg {
  fill: var(--brand-primary-500);
}
.floating-placeholder > svg {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
}
.floating-placeholder label {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  pointer-events: none;
  font-size: 0.9375rem;
  padding-bottom: 0;
  overflow: hidden;
  padding-right: 20px;
  width: 100%;
  height: 100%;
  position: absolute;
}
.floating-placeholder label:before, .floating-placeholder label:after {
  position: relative;
}
.floating-placeholder label:before, .floating-placeholder label:after {
  content: "";
  display: block;
}
.floating-placeholder label:before {
  content: attr(aria-label);
  transform-origin: left top;
  left: 50px;
  transition: all 0.3s;
  max-width: calc(100% - 30px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.floating-placeholder label:before {
  transform: translate3d(0, -15px, 0) scale3d(0.75, 0.75, 1);
}
.floating-placeholder label:after {
  height: 2px;
  position: absolute;
  opacity: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: var(--brand-primary-500);
  transform: scale3d(0, 1, 1);
  transition: all 0.3s;
}

.radial-percentage {
  display: block;
  margin: 10px auto;
  max-height: 200px;
}
.radial-percentage .fill {
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke: var(--brand-primary-500);
  animation: progress 1s ease-out forwards;
  transition: all 0.3s;
}
.radial-percentage .background {
  fill: none;
  stroke: var(--brand-primary-500);
  stroke-width: 2.8;
  opacity: 0.1;
}
.radial-percentage text {
  fill: #666;
  text-anchor: middle;
  font-family: sans-serif;
}
.radial-percentage text.percentage {
  font-size: 0.5rem;
}
.radial-percentage text:not(.percentage) {
  font-size: 0.175rem;
}

.tooltip {
  display: block;
  z-index: 10000;
}
.tooltip .tooltip-inner {
  background: #424242;
  color: white;
  border-radius: 5px;
  padding: 0.9375rem;
  max-width: 250px;
  text-align: center;
  font-size: 0.9375rem;
}
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: #424242;
  z-index: 1;
}
.tooltip[x-placement^=top] {
  margin-bottom: 5px;
}
.tooltip[x-placement^=top] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^=bottom] {
  margin-top: 5px;
}
.tooltip[x-placement^=bottom] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^=right] {
  margin-left: 5px;
}
.tooltip[x-placement^=right] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[x-placement^=left] {
  margin-right: 5px;
}
.tooltip[x-placement^=left] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: rgb(40, 40, 40);
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(40, 40, 40, 0.1);
}
.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}
.tooltip[aria-hidden=true] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden=false] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.image-gallery .title {
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: 1px solid #dee2e6;
}
.image-gallery .gallery ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 25px;
}
@media only screen and (max-width: 1000px) {
  .image-gallery .gallery ul {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
.image-gallery .gallery ul li {
  background-color: rgb(255, 255, 255);
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.15);
  border: 1px solid #dee2e6;
  transition: all 0.3s;
}
.image-gallery .gallery ul li:hover {
  transform: translate3d(0, -2px, 0);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.12), 0 6px 18px rgba(0, 0, 0, 0.12);
}
.image-gallery .gallery ul li:focus-within {
  transform: translate3d(0, -2px, 0);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.12), 0 6px 18px rgba(0, 0, 0, 0.12);
}
.image-gallery .gallery ul li button {
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  width: 100%;
  position: relative;
}
.image-gallery .gallery ul li button .full-screen {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 100%;
  background-color: #fff;
  padding: 7.5px;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid #dee2e6;
  width: 35px;
  height: 35px;
}
.image-gallery .gallery ul li button .full-screen svg {
  width: 100%;
  height: 100%;
}
.image-gallery .gallery ul li button img {
  object-fit: cover;
  object-position: 50%;
  width: 100%;
  flex: 1;
  max-height: 300px;
}
.image-gallery .gallery ul li button .content {
  padding: 20px;
  border-top: 1px solid #dee2e6;
  flex-shrink: 0;
  width: 100%;
}
.image-gallery .gallery ul li button .content p {
  font-weight: 600;
}
.image-gallery .gallery ul li button:hover .full-screen, .image-gallery .gallery ul li button:focus .full-screen {
  opacity: 1;
}
.image-gallery:not(:last-child) {
  margin-bottom: 20px;
}

.clients {
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
@media only screen and (max-width: 1000px) {
  .clients {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}
.clients li img {
  max-width: 175px;
  height: 75px;
  margin: 0 auto;
  margin-bottom: 15px;
}
@media only screen and (max-width: 1000px) {
  .clients li img {
    max-width: 100%;
  }
}
.clients li p {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.has-piano-grid .content {
  display: flex;
}
@media only screen and (max-width: 1366px) {
  .has-piano-grid .content {
    flex-direction: column;
  }
}
.has-piano-grid .content > * {
  flex: 1;
}
.has-piano-grid .piano-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
}
.has-piano-grid .piano-grid li {
  height: 250px;
}
.has-piano-grid .piano-grid img {
  object-fit: cover;
  object-position: 50%;
  max-height: 100%;
  width: 100%;
}
@media only screen and (max-width: 1366px) {
  .has-piano-grid .piano-grid {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 500px) {
  .has-piano-grid .piano-grid {
    grid-template-columns: 1fr;
  }
}

header {
  color: #fff;
}
header .cta {
  padding: 15px 30px;
  background-color: #fff;
  color: var(--brand-primary-500);
  display: inline-flex;
  font-size: 18px;
  font-weight: 600;
  margin-top: 50px;
}
@media only screen and (max-width: 1000px) {
  header .cta {
    margin-top: 25px;
  }
}

header,
nav {
  background-color: var(--brand-primary-500);
}

nav,
header .heading-details,
section.has-clients,
main,
footer {
  padding: 50px 75px;
  position: relative;
}
@media only screen and (max-width: 1366px) {
  nav,
header .heading-details,
section.has-clients,
main,
footer {
    padding: 30px 55px;
  }
}
@media only screen and (max-width: 1366px) {
  nav,
header .heading-details,
section.has-clients,
main,
footer {
    padding: 20px 30px;
  }
}
@media only screen and (max-width: 1000px) {
  nav,
header .heading-details,
section.has-clients,
main,
footer {
    padding: 10px 20px;
  }
}

section.has-clients {
  padding-top: 0;
}

nav,
footer {
  display: flex;
}
nav > a,
footer > a {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.25rem;
}
@media only screen and (max-width: 1000px) {
  nav > a,
footer > a {
    margin-bottom: 15px;
  }
}
nav > a svg,
footer > a svg {
  width: 50px;
  height: 50px;
  display: inline-block;
  margin-right: 10px;
}
nav ul,
nav .details,
footer ul,
footer .details {
  flex: 1;
}
@media only screen and (max-width: 1000px) {
  nav ul,
nav .details,
footer ul,
footer .details {
    text-align: left;
  }
}

nav {
  display: flex;
  color: #fff;
  align-items: center;
}
nav > a {
  color: #fff;
}
nav > a svg {
  fill: #fff;
}
nav ul {
  justify-content: flex-end;
  display: flex;
}
@media only screen and (max-width: 1000px) {
  nav ul {
    justify-content: flex-start;
    white-space: nowrap;
    overflow: auto;
  }
}
nav ul li:not(:last-child) {
  margin-right: 30px;
}
nav ul li a {
  color: #fff;
}
nav ul li a.active {
  text-decoration: underline;
}

footer {
  text-align: right;
  padding-top: 50px;
  border-top: 2px solid var(--brand-primary-500);
  font-weight: 700;
  background-color: #f8f8f8;
}
footer > a {
  color: var(--brand-primary-500);
}
footer > a svg {
  fill: var(--brand-primary-500);
}

main:not(.home) {
  padding-top: 0;
  position: relative;
}
main:not(.home):before, main:not(.home):after {
  position: absolute;
}
main:not(.home):before, main:not(.home):after {
  content: "";
  display: block;
}
@media only screen and (max-width: 1366px) {
  main:not(.home) {
    padding: 0 30px 55px 30px;
  }
}
@media only screen and (max-width: 1000px) {
  main:not(.home) {
    padding: 0 15px 30px 20px;
  }
}
main:not(.home):before {
  width: 100%;
  height: 200px;
  top: 0;
  left: 0;
  background-color: var(--brand-primary-500);
  z-index: -1;
}
main:not(.home).contact .wrapper {
  background-color: #fff;
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.15);
}
main:not(.home).contact .wrapper .content {
  box-shadow: none;
}
main:not(.home).contact .wrapper .content .users {
  display: flex;
  gap: 20px;
}
main:not(.home).contact .wrapper .content .users .user:not(:first-child) {
  border-left: 3px dashed #e9ecef;
  padding-left: 30px;
}
@media only screen and (max-width: 1000px) {
  main:not(.home).contact .wrapper .content .users .user:not(:first-child) {
    padding-left: initial;
    border-left: initial;
    border-top: 3px dashed #e9ecef;
    padding-top: 30px;
  }
}
main:not(.home).contact .wrapper .content .users .user:not(:last-child) {
  padding-right: 30px;
}
main:not(.home).contact .wrapper .content .users > * {
  flex: 1;
}
@media only screen and (max-width: 1000px) {
  main:not(.home).contact .wrapper .content .users {
    flex-direction: column;
  }
}
main:not(.home) .content {
  padding: 50px;
  background-color: #fff;
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 1366px) {
  main:not(.home) .content {
    padding: 25px;
  }
}
main:not(.home) .content h1 {
  font-weight: 400;
}

.socials {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.socials li a {
  border-radius: 100%;
  padding: 10px;
  border-width: 2px;
  border-style: solid;
  display: block;
  background-color: #fff;
  width: 45px;
  height: 45px;
  transition: all 0.3s;
}
.socials li a[href*=facebook] {
  border-color: var(--facebook-brand);
  color: var(--facebook-brand);
}
.socials li a[href*="07"] {
  border-color: var(--whatsapp-brand);
  color: var(--whatsapp-brand);
}
.socials li a[href*=mail] {
  border-color: var(--brand-primary-500);
  color: var(--brand-primary-500);
}
.socials li a:hover, .socials li a:focus {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.12), 0 6px 18px rgba(0, 0, 0, 0.12);
  transform: translate3d(0, -2px, 0);
}
.socials li a svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
}

.logo {
  font-family: "Dancing Script", cursive;
  font-size: 44px;
}
@media only screen and (max-width: 1366px) {
  .logo {
    font-size: 36px;
  }
}
@media only screen and (max-width: 1000px) {
  .logo {
    flex: 1;
    font-size: 28px;
  }
}

.mobile-menu-toggle {
  color: #fff;
  width: 30px;
  height: 30px;
}
.mobile-menu-toggle svg {
  fill: currentColor;
}

ul.is-mobile {
  position: fixed;
  top: 0;
  right: -90%;
  width: 90%;
  height: 100%;
  background-color: #fff;
  flex-direction: column;
  z-index: 9;
  padding: 20px;
  transition: 0.3s;
}
ul.is-mobile.mobile-navigation-active {
  right: 0;
}
ul.is-mobile .mobile-menu-close {
  display: flex;
  justify-content: flex-end;
}
ul.is-mobile .mobile-menu-close button {
  color: rgb(40, 40, 40);
  width: 30px;
  height: 30px;
}
ul.is-mobile .mobile-menu-close svg {
  fill: currentColor;
}
ul.is-mobile li {
  margin: initial !important;
}
ul.is-mobile a {
  color: rgb(40, 40, 40);
  font-size: 18px;
  padding: 10px;
  display: block;
}

.mobile-overlay {
  background-color: rgba(40, 40, 40, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100%;
}

.home header {
  display: flex;
  min-height: 700px;
}
@media only screen and (max-width: 1000px) {
  .home header {
    min-height: initial;
  }
}
.home header .heading-details {
  flex: 1;
  padding-right: 100px;
}
@media only screen and (max-width: 1000px) {
  .home header .heading-details {
    padding-right: 20px;
  }
}
.home header .heading-details h1 {
  font-size: 50px;
  color: #a7e8e0;
  padding-bottom: 30px;
}
@media only screen and (max-width: 1000px) {
  .home header .heading-details h1 {
    font-size: 32px;
  }
}
.home header .heading-details h1 > p {
  font-size: 20px;
}
.home header img {
  flex-shrink: 0;
  width: 800px;
  position: relative;
  top: 50px;
  object-fit: cover;
  object-position: 50%;
}
@media only screen and (max-width: 1366px) {
  .home header img {
    width: 500px;
  }
}
@media only screen and (max-width: 1000px) {
  .home header img {
    display: none;
  }
}
.home main {
  display: flex;
}
@media only screen and (max-width: 1000px) {
  .home main {
    flex-direction: column;
  }
}
.home main .overview {
  flex: 1;
  padding-right: 100px;
  padding-top: 50px;
}
@media only screen and (max-width: 1366px) {
  .home main .overview {
    padding-right: 60px;
  }
}
@media only screen and (max-width: 1000px) {
  .home main .overview {
    padding-right: initial;
    padding-top: initial;
  }
}
.home main .overview h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 30px;
}
.home main .overview > p {
  font-size: 20px;
}
.home main .has-quote {
  width: 725px;
}
@media only screen and (max-width: 1366px) {
  .home main .has-quote {
    max-width: 470px;
    width: initial;
  }
}
@media only screen and (max-width: 1000px) {
  .home main .has-quote {
    margin: 15px 0;
  }
}
.home main .has-quote blockquote {
  padding: 50px;
  color: #fff;
  background-color: #1f1d27;
  max-width: 500px;
  position: relative;
}
.home main .has-quote blockquote .quote {
  margin-bottom: 40px;
  font-style: italic;
}
.home main .has-quote blockquote .quote + p {
  font-weight: 700;
}
.home main .has-quote blockquote:before {
  content: '"';
  font-family: Arial, Helvetica, sans-serif;
  font-size: 100px;
  position: absolute;
  top: 12.5px;
  left: 12.5px;
  opacity: 0.15;
  user-select: none;
}

iframe[src*=google] {
  width: 100%;
}

.contact .inline-img {
  float: left;
  width: 100%;
  max-width: 300px;
  margin-right: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 700px) {
  .contact .inline-img {
    float: none;
  }
}
.contact .has-map {
  background-color: #fff;
}
.contact iframe {
  min-height: 400px;
}

.tuning .tuning-images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.tuning .tuning-images img {
  width: 600px;
  max-width: 100%;
}

:root {
  --brand-primary-500: #223b40;
  --facebook-brand: #4267B2;
  --whatsapp-brand: #128C7E;
  --email-brand: #1f1d27;
}