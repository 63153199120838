@use "../abstracts/variables";
@use "../abstracts/mixins";

*,
*:before,
*:after,
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: none;
    box-sizing: border-box;
    vertical-align: baseline;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: contain;
    -webkit-font-smoothing: antialiased;
}

*:before,
*:after {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    text-decoration: inherit;
}

input::placeholder,
input::-webkit-input-placeholder,
input::-moz-placeholder {
    opacity: 0.85;
}

::placeholder,
::-webkit-input-placeholder,
::-moz-placeholder {
    @include mixins.darkMode {
        color: variables.$grey;
    }
}

a {
    color: darken(variables.$blue, 10%);
    font-weight: 600;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

address {
    font-style: normal;

    &:not(:last-child) {
        padding-bottom: variables.$rem-size-3;
    }

    li {
        &:not(:last-child) {
            padding-bottom: 5px;
        }
    }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
picture {
    display: block;
}

figcaption {
    display: none;
}

body,
button,
input,
select,
textarea {
    font-family: variables.$font-family;
}

button,
input,
textarea {
    appearance: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: none;
}

blockquote {
    position: relative;
    padding: 20px;
    padding-left: 40px;
    background-color: variables.$grey;
    @include mixins.pseudo;

    &:before {
        content: '"';
        top: 10px;
        left: 10px;
        font-size: 50px;
        font-family: sans-serif;
        opacity: 0.5;
    }

    &:not(:last-child) {
        margin-bottom: variables.$rem-size-3;
    }
}

body {
    background-color: variables.$grey-100;

    &.no-scroll {
        overflow: hidden;
    }
}

button {
    background-color: transparent;
}

.clear {
    clear: both;
}

*:disabled {
    cursor: not-allowed;
}

.d-none,
.inline-svg {
    display: none !important;
}

.d-block {
    display: block;
}

svg {
    display: block;
    @include mixins.eq-size(100%);
    @include mixins.transition;
}

.v-hidden {
    position: absolute;
    left: -99999px;
}

fieldset {
    min-width: initial;
    display: block;
}

form {
    &:not(.width-auto) {
        width: 100%;
    }

    fieldset {
        position: relative;
    }
}

p:empty {
    display: none;
}

html,
body {
    min-height: 100vh;
    -webkit-overflow-scrolling: touch;
    color: variables.$black;
}

iframe {
    width: 100%;
    height: 100%;
    display: block;
}

img {
    display: block;
    height: auto;
    max-width: 100%;
}

.link {
    cursor: pointer;
    text-decoration: none;
    color: darken(variables.$blue, 10%);
    font-weight: 700;

    &:hover {
        text-decoration: underline;
    }

    &:disabled {
        cursor: not-allowed;
    }
}

ol,
ul {
    &:not(.has-bullet-points) {
        list-style: none;
    }

    &.has-bullet-points {
        padding-left: 20px;

        li {
            &:not(:last-of-type) {
                padding-bottom: variables.$rem-size-3;
            }

            ul {
                padding-top: variables.$rem-size-3;
            }
        }
    }

    &:not(:last-child) {
        padding-bottom: variables.$rem-size-3;
    }
}

p {
    word-break: break-word;

    &:empty {
        padding-bottom: 0;
    }
}

section {
    width: 100%;
    position: relative;
}

select::-ms-expand {
    display: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;

    caption {
        display: none;
        visibility: hidden;
        opacity: 0;
    }
}

.text-off-screen {
    text-indent: -99999px;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-center {
    text-align: center;
}

.bold {
    font-weight: bold;
}

.word-break-initial,
.word-break-initial * {
    word-break: initial !important;
}

.center {
    margin: 0 auto;
    display: block;
}

.skip-to-content {
    position: fixed;
    top: -1000px;
    left: -1000px;
    height: 1px;
    width: 1px;
    overflow: hidden;
    z-index: 999;
    padding: variables.$rem-size-4;
    background-color: variables.$black;
    color: #ffffff;
    font-size: variables.$rem-size-4;
}

.user-tabbing {
    .skip-to-content {
        &:active,
        &:focus,
        &:hover {
            left: 0;
            top: 0;
            width: auto;
            height: auto;
            overflow: visible;
            outline-color: yellow;
        }
    }
}

@media (prefers-reduced-motion) {
    *,
    *:before,
    *:after {
        transition: none !important;
    }
}

.mb-1 {
    margin-bottom: variables.$rem-size-1;
}

.mb-2 {
    margin-bottom: variables.$rem-size-2;
}

.mb-3 {
    margin-bottom: variables.$rem-size-3;
}

.mb-4 {
    margin-bottom: variables.$rem-size-4;
}

.mt-1 {
    margin-top: variables.$rem-size-1;
}

.mt-2 {
    margin-top: variables.$rem-size-2;
}

.mt-3 {
    margin-top: variables.$rem-size-3;
}

.mt-4 {
    margin-top: variables.$rem-size-4;
}

.error{
    color: variables.$red;
}