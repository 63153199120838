@use "../abstracts/variables";
@use "../abstracts/mixins";

.button {
    cursor: pointer;
    padding: 10px 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: variables.$black;
    background-color: #fff;
    border-radius: 5px;
    font-weight: 600;
    box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.15);
    text-align: center;
    font-size: 18px;
    border: 2px solid variables.$grey-300;
    position: relative;
    @include mixins.transition;

    &:hover,
    &:focus {
        transform: translate3d(0, -2px, 0);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.12), 0 6px 18px rgba(0, 0, 0, 0.12);
        text-decoration: underline;
    }

    &.button-primary {
        background-color: variables.$primary-blue-400;
        color: #fff;
        border-color: darken(variables.$primary-blue-400, 20%);
    }
}
