@use "../abstracts/variables";
@use "../abstracts/mixins";

// Sale layout removed due to complexity issues with maintenance

// .piano-for-sale {
//     border-bottom: 1px solid #ddd;
//     padding: 10px;
//     border-radius: 10px;
//     box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.15);
//     background-color: #fff;
//     display: flex;
//     @include mixins.transition;
//     @include mixins.border-all;

//     .has-thumbnail {
//         margin-right: 25px;
//         flex-shrink: 0;

//         .thumbnail {
//             border-radius: 10px;
//             margin-bottom: 10px;
//             object-fit: cover;
//             object-position: 50%;
//             @include mixins.border-all;
//             @include mixins.eq-size(200px);
//         }

//         .button {
//             width: 100%;
//         }
//     }

//     .details {
//         flex: 1;
//     }

//     &:not(:last-child) {
//         margin-bottom: 25px;
//     }

//     @include mixins.media-query(custom, max-width, 750px){
//         flex-direction: column;

//         .has-thumbnail{
//             margin-right: initial;

//             .thumbnail{
//                 width: 100%;
//             }

//             .button{
//                 margin-bottom: 10px;
//             }
//         }
//     }
// }

.has-piano-grid {
    .content {
        display: flex;

        @include mixins.media-query(laptop) {
            flex-direction: column;
        }

        & > * {
            flex: 1;
        }
    }

    .piano-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-template-columns: 1fr 1fr;
        grid-gap: 25px;

        li {
            height: 250px;
        }

        img {
            object-fit: cover;
            object-position: 50%;
            max-height: 100%;
            width: 100%;
        }

        @include mixins.media-query(laptop) {
            margin-top: 20px;
        }

        @include mixins.media-query(mobile) {
            grid-template-columns: 1fr;
        }
    }
}