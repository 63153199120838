@use "../abstracts/variables";
@use "../abstracts/mixins";

.socials {
    display: flex;
    gap: 10px;
    justify-content: flex-end;

    li {
        a {
            border-radius: 100%;
            padding: 10px;
            border-width: 2px;
            border-style: solid;
            display: block;
            background-color: #fff;
            @include mixins.eq-size(45px);
            @include mixins.transition;

            &[href*="facebook"] {
                border-color: var(--facebook-brand);
                color: var(--facebook-brand);
            }

            &[href*="07"] {
                border-color: var(--whatsapp-brand);
                color: var(--whatsapp-brand);
            }

            &[href*="mail"] {
                border-color: var(--brand-primary-500);
                color: var(--brand-primary-500);
            }

            &:hover,
            &:focus {
                box-shadow: 0 4px 4px rgba(0, 0, 0, .24), 0 0 4px rgba(0, 0, 0, .12), 0 6px 18px rgba(0, 0, 0, .12);
                transform: translate3d(0, -2px, 0);
            }

            svg{
                fill: currentColor;
                @include mixins.eq-size(100%);
            }
        }
    }
}
