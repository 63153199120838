@use "../abstracts/variables";
@use "../abstracts/mixins";

.empty-state {
    border-radius: 100%;
    background-color: variables.$grey-100;
    padding: variables.$rem-size-3;
    margin: 0 auto;
    margin-bottom: 20px;
    @include mixins.eq-size(80px);
    @include mixins.border-all;
    border-width: 2px;

    .icon {
        @include mixins.eq-size(100%);

        &.success {
            fill: variables.$green;
        }
    }
}

.empty-state-illustration {
    .highlight {
        fill: var(--brand-primary-500);
    }

    .white {
        fill: #fff;
    }

    .off-white {
        fill: #f2f2f2;
    }

    .light-skin {
        fill: #ffb8b8;
    }

    .off-black {
        fill: #3f3d56;
    }

    .grey {
        fill: #cccccc;
    }
}