@use "../abstracts/mixins";
@use "../abstracts/variables";

html {
    font-size: 1em;
}

button,
input,
p,
select,
textarea {
    font-size: 1rem;
}

h1:not(:last-child),
h2:not(:last-child),
h3:not(:last-child),
h4:not(:last-child),
h5:not(:last-child),
h6:not(:last-child),
p:not(:last-child) {
    padding-bottom: variables.$rem-size-3;
}

h1 {
    font-weight: 700;
    font-size: 2.125rem;

    @include mixins.media-query(tablet){
        font-size: 1.75rem;
    }
}

h2 {
    font-weight: 600;
    font-size: 1.75rem;

    @include mixins.media-query(tablet){
        font-size: 1.5rem;
    }
}

h3 {
    font-weight: 500;
    font-size: 1.5rem;

    @include mixins.media-query(tablet){
        font-size: 1.25rem;
    }
}

h4 {
    font-weight: 400;
    font-size: 1.25rem;
}

h5 {
    font-weight: 300;
    font-size: 1.125rem;
}

h6 {
    font-weight: 300;
    font-size: 1rem;
}