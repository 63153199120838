@use "../abstracts/variables";
@use "../abstracts/mixins";

.tuning {
    .tuning-images{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;

        img{
            width: 600px;
            max-width: 100%;
        }
    }
}