@import url("https://use.typekit.net/qtp1qcs.css");
$font-family: 'proxima-nova', sans-serif;

$primary-blue-400: #1a73e8;
$primary-blue-500: #00a9ff;
$primary-blue-700: #007fbf;
$primary-blue-800: #0080bf;

$grey-100: #f8f9fa;
$grey-200: #e9ecef;
$grey-300: #dee2e6;
$grey-500: #dddddd;
$grey-600: #6c757d;
$grey-700: #495057;
$grey-800: #212529;

$blue: #7854F6;
$black: rgb(40, 40, 40);
$grey: #f8f8f8;
$red: #E22C2C;
$green: #388505;

$desktopBreakpoint: 1920px;
$laptopBreakpoint: 1366px;
$tabletBreakpoint: 1000px;
$mobileBreakpoint: 500px;

$rem-size-1: 0.3125rem;
$rem-size-1-5: 0.4375rem;
$rem-size-2: 0.625rem;
$rem-size-3: 0.9375rem;
$rem-size-4: 1.25rem;
$rem-size-5: 1.563rem;
$rem-size-6: 1.875rem;
$rem-size-7: 2.188rem;
$rem-size-8: 2.5rem;
$rem-size-9: 2.813rem;
$rem-size-10: 3.125rem;
$rem-size-11: 3.438rem;
$rem-size-12: 3.75rem;
$rem-size-13: 4.063rem;
$rem-size-14: 4.375rem;
$rem-size-15: 4.688rem;
$rem-size-16: 5rem;
$rem-size-17: 5.313rem;
$rem-size-18: 5.625rem;
$rem-size-19: 5.938rem;
$rem-size-20: 6.25rem;

$imagePath: "/img";