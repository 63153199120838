@use "./base/reset";
@use "./base/typography";

@use "./components/animations";
@use "./components/button";
@use "./components/empty-state";
@use "./components/inputs";
@use "./components/tooltip";
@use "./components/image-gallery";
@use "./components/clients";
@use "./components/pianos-for-sale";

@use "./layout/header";
@use "./layout/main";
@use "./layout/footer";
@use "./layout/navigation";

@use "./pages/home";
@use "./pages/contact";
@use "./pages/tuning";

:root{
    --brand-primary-500: #223b40;
    --facebook-brand: #4267B2;
    --whatsapp-brand: #128C7E;
    --email-brand: #1f1d27;
}