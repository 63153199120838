@use "../abstracts/variables";
@use "../abstracts/mixins";

.fade {
    &-enter-active,
    &-leave-active {
        @include mixins.transition;
    }

    &-enter,
    &-leave-to {
        opacity: 0;
    }
}

@include mixins.keyframes(loading) {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@include mixins.keyframes(check-box-feedback) {
    50% {
        transform: scale(0.8);
    }
}

@include mixins.keyframes(progress) {
    0% {
        stroke-dasharray: 0 100;
    }
}
