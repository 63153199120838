@use "../abstracts/variables";
@use "../abstracts/mixins";

.clients {
    display: grid;
    gap: 50px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

    @include mixins.media-query(tablet) {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }

    li {
        img {
            max-width: 175px;
            height: 75px;
            margin: 0 auto;
            margin-bottom: 15px;

            @include mixins.media-query(tablet) {
                max-width: 100%;
            }
        }

        p {
            font-size: 14px;
            font-weight: 600;
            text-align: center;
        }
    }
}
