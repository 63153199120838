@use "../abstracts/variables";
@use "../abstracts/mixins";

.home {
    header {
        display: flex;
        min-height: 700px;

        @include mixins.media-query(tablet) {
            min-height: initial;
        }
    
        .heading-details {
            flex: 1;
            padding-right: 100px;

            @include mixins.media-query(tablet) {
                padding-right: 20px;
            }
    
            h1 {
                font-size: 50px;
                color: #a7e8e0;
                padding-bottom: 30px;

                @include mixins.media-query(tablet) {
                    font-size: 32px;
                }
    
                & > p {
                    font-size: 20px;
                }
            }
        }
    
        img {
            flex-shrink: 0;
            width: 800px;
            position: relative;
            top: 50px;
            object-fit: cover;
            object-position: 50%;

            @include mixins.media-query(laptop) {
                width: 500px;
            }

            @include mixins.media-query(tablet) {
                display: none;
            }
        }
    }
    main {
        display: flex;

        @include mixins.media-query(tablet) {
            flex-direction: column;
        }

        .overview {
            flex: 1;
            padding-right: 100px;
            padding-top: 50px;

            @include mixins.media-query(laptop) {
                padding-right: 60px;
            }

            @include mixins.media-query(tablet) {
                padding-right: initial;
                padding-top: initial;
            }

            h2 {
                font-size: 26px;
                font-weight: 700;
                margin-bottom: 30px;
            }

            & > p {
                font-size: 20px;
            }
        }

        .has-quote {
            width: 725px;

            @include mixins.media-query(laptop) {
                max-width: 470px;
                width: initial;
            }

            @include mixins.media-query(tablet) {
                margin: 15px 0;
            }

            blockquote {
                padding: 50px;
                color: #fff;
                background-color: #1f1d27;
                max-width: 500px;
                position: relative;

                .quote {
                    margin-bottom: 40px;
                    font-style: italic;

                    & + p {
                        font-weight: 700;
                    }
                }

                &:before {
                    content: '"';
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 100px;
                    position: absolute;
                    top: 12.5px;
                    left: 12.5px;
                    opacity: 0.15;
                    user-select: none;
                }
            }
        }
    }
}

iframe {
    &[src*="google"] {
        width: 100%;
    }
}